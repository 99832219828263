import { createClient, postForm, baseUrl } from './util';

const client = createClient('pros');

client.logo = (id, file) => {
  if (!file) return;
  let fd = new FormData();
  fd.append('photo', file);
  return postForm(`${baseUrl}/pros/${id}/logo`, fd);
};

client.photo = (id, file) => {
  if (!file) return;
  let fd = new FormData();
  fd.append('photo', file);
  return postForm(`${baseUrl}/pros/${id}/photo`, fd);
};

export default client;
