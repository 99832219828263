import {
  baseUrl,
  createClient,
  createQueryString,
  get,
  getToken,
  post,
  postForm,
  put,
  del
} from './util';

const client = createClient('projects', {
  visits: null,
  events: null,
  notes: null,
  devis: null,
  operations: null,
  medias: null,
  documents: null,
  payments: null,
  maps: null,
  plans: null
});

client.countDevisByProject = id => get(`${baseUrl}/projects/${id}/devis/count`);

client.statuses = () => get(`${baseUrl}/projects/status`);

client.updateStatus = (id, data) =>
  post(`${baseUrl}/projects/${id}/status`, data);

client.history = id => get(`${baseUrl}/projects/${id}/history`);

client.location = params =>
  get(`${baseUrl}/projects/location${createQueryString(params)}`);

client.report = (id, data) => post(`${baseUrl}/reports/project/${id}`, data);

client.sendReport = (id, docId) =>
  get(`${baseUrl}/reports/project/${id}/send/${docId}`);

client.reportProjectsStatusUser = params =>
  get(`${baseUrl}/reports/projects/status${createQueryString(params)}`);

client.script = params =>
  get(`${baseUrl}/projectsScript/script${createQueryString(params)}`);

client.allVisits = params =>
  get(`${baseUrl}/projects/visits${createQueryString(params)}`);

client.allNotes = params =>
  get(`${baseUrl}/projects/notes${createQueryString(params)}`);

client.allEvents = params =>
  get(`${baseUrl}/projects/events${createQueryString(params)}`);

client.createOperations = (id, data) =>
  post(`${baseUrl}/projects/${id}/operations/many`, data);

client.duplicateDevis = (id, did) =>
  get(`${baseUrl}/projects/${id}/devis/duplicate/${did}`);

client.updateDevisMaterials = (id, did, data) =>
  put(`${baseUrl}/projects/${id}/devis/${did}/materials`, data);

client.devisPdfUrl = (id, did) =>
  getToken().then(token =>
    Promise.resolve({
      url: `${baseUrl}/projects/${id}/devis/${did}/pdf`,
      httpHeaders: {
        Authorization: 'Bearer ' + token
      },
      withCredentials: false
    })
  );

client.devisCompoundPdfUrl = id =>
  getToken().then(token =>
    Promise.resolve({
      url: `${baseUrl}/projects/${id}/compound/devis/pdf`,
      httpHeaders: {
        Authorization: 'Bearer ' + token
      },
      withCredentials: false
    })
  );

client.estimateDevis = (id, data) =>
  post(`${baseUrl}/projects/${id}/devis/estimation`, data);

client.allDevisDocuments = (pid, did) =>
  get(`${baseUrl}/projects/${pid}/devis/${did}/documents`);

client.oneDevisDocuments = (pid, did, id) =>
  get(`${baseUrl}/projects/${pid}/devis/${did}/documents/${id}`);

client.createDevisDocuments = (pid, did, data) =>
  postForm(`${baseUrl}/projects/${pid}/devis/${did}/documents`, data);

client.updateDevisDocuments = (pid, did, data) =>
  put(`${baseUrl}/projects/${pid}/devis/${did}/documents/${data._id}`, data);

client.delDevisDocuments = (pid, did, id) =>
  del(`${baseUrl}/projects/${pid}/devis/${did}/documents/${id}`);

client.sendDocByEmail = (pid, id, data) =>
  post(`${baseUrl}/projects/${pid}/documents/${id}/send`, data);

client.sendEventByEmail = (pid, id, data) =>
  post(`${baseUrl}/projects/${pid}/events/${id}/send`, data);

client.sendNoteByEmail = (pid, id, data) =>
  post(`${baseUrl}/projects/${pid}/notes/${id}/send`, data);

client.updateAllNotes = () => get(`${baseUrl}/projects/updateallnotes`);

client.children = params =>
  get(`${baseUrl}/projects/children${createQueryString(params)}`);

client.updateSupervisors = (pid, data) =>
  post(`${baseUrl}/projects/${pid}/supervisors`, data);

client.updateSellers = (pid, data) =>
  post(`${baseUrl}/projects/${pid}/sellers`, data);

client.addNoteMedia = (pid, id, data) =>
  postForm(`${baseUrl}/projects/${pid}/notes/${id}/images`, data);

client.deleteNoteMedia = (pid, nid, id) =>
  get(`${baseUrl}/projects/${pid}/notes/${nid}/images/${id}`);

client.updateNoteMedia = (pid, nid, data) =>
  put(`${baseUrl}/projects/${pid}/notes/${nid}/images/${data._id}`, data);

client.postulatedUsers = id => get(`${baseUrl}/projects/${id}/postulates`);

client.paymentPdfBills = (pid, id, data) =>
  post(`${baseUrl}/projects/${pid}/payments/${id}/bills`, data);

client.paymentPdfBill = (pid, id, params) =>
  getToken().then(token =>
    Promise.resolve({
      url: `${baseUrl}/projects/${pid}/payments/${id}/bill${createQueryString(
        params
      )}`,
      httpHeaders: {
        Authorization: 'Bearer ' + token
      },
      withCredentials: false
    })
  );

/* Plans endpoints */
client.getPlanNotes = (pid, planId) =>
  get(`${baseUrl}/projects/${pid}/plans/${planId}/notes`);

client.getPlanNote = (pid, planId, id) =>
  get(`${baseUrl}/projects/${pid}/plans/${planId}/notes/${id}`);

client.addPlanNote = (pid, planId, data) =>
  post(`${baseUrl}/projects/${pid}/plans/${planId}/notes`, data);

client.savePlanNote = (pid, planId, id, data) =>
  put(`${baseUrl}/projects/${pid}/plans/${planId}/notes/${id}`, data);

client.deletePlanNote = (pid, planId, id) =>
  del(`${baseUrl}/projects/${pid}/plans/${planId}/notes/${id}`);

client.sendPlanNoteByEmail = (pid, planId, id, data) =>
  post(`${baseUrl}/projects/${pid}/plans/${planId}/notes/${id}/send`, data);

client.getPlanTasks = (pid, planId) =>
  get(`${baseUrl}/projects/${pid}/plans/${planId}/tasks`);

client.getPlanTask = (pid, planId, id) =>
  get(`${baseUrl}/projects/${pid}/plans/${planId}/tasks/${id}`);

client.addPlanTask = (pid, planId, data) =>
  post(`${baseUrl}/projects/${pid}/plans/${planId}/tasks`, data);

client.savePlanTask = (pid, planId, id, data) =>
  put(`${baseUrl}/projects/${pid}/plans/${planId}/tasks/${id}`, data);

client.deletePlanTask = (pid, planId, id) =>
  del(`${baseUrl}/projects/${pid}/plans/${planId}/tasks/${id}`);

client.sendPlanTaskByEmail = (pid, planId, id, data) =>
  post(`${baseUrl}/projects/${pid}/plans/${planId}/tasks/${id}/send`, data);

client.getPlanImages = (pid, planId) =>
  get(`${baseUrl}/projects/${pid}/plans/${planId}/images`);

client.getPlanImage = (pid, planId, id) =>
  get(`${baseUrl}/projects/${pid}/plans/${planId}/images/${id}`);

client.addPlanImage = (pid, planId, data) =>
  postForm(`${baseUrl}/projects/${pid}/plans/${planId}/images`, data);

client.savePlanImage = (pid, planId, id, data) =>
  put(`${baseUrl}/projects/${pid}/plans/${planId}/images/${id}`, data);

client.deletePlanImage = (pid, planId, id) =>
  del(`${baseUrl}/projects/${pid}/plans/${planId}/images/${id}`);

client.sendPlanImageByEmail = (pid, planId, id, data) =>
  post(`${baseUrl}/projects/${pid}/plans/${planId}/images/${id}/send`, data);

export default client;
