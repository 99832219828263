/**
 *
 * Show notification
 *
 * @param message
 * @param type
 * @param icon
 * @param place
 * @return {function(*, *, {notify: *}): *}
 */
export const ring = ( message, type, icon, place = 'tr') => (dispatch, getState, { notify }) => notify(message, type, icon, place)

export const notify = (type, message) => (dispatch) => {
    const icons = {
        'success': 'now-ui-icons ui-1_check',
        'primary': 'now-ui-icons ui-2_settings-90',
        'danger': 'now-ui-icons travel_info',
        'warning': 'now-ui-icons business_bulb-63',
        'info': 'now-ui-icons travel_info'
    }
    dispatch({
        type: 'NOTIFICATION_ADD',
        payload: {
            icon: icons[type],
            type,
            message: message
        }
    })
    return Promise.resolve()
}