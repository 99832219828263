import { baseUrl, createClient, postForm } from './util';

const client = createClient('sellers');

client.photo = (id, file) => {
  if (!file) return;
  let fd = new FormData();
  fd.append('photo', file);
  return postForm(`${baseUrl}/sellers/${id}/photo`, fd);
};

export default client;
