import { notify } from './index';

export const all = params => (dispatch, getState, { api }) =>
  api.Operations.all(params).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const one = id => (dispatch, getState, { api }) =>
  api.Operations.one(id).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const save = op => (dispatch, getState, { api }) =>
  api.Operations.save(op)
    .then(p => {
      dispatch(notify('info', 'Operation saved'));
      return Promise.resolve(p);
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const remove = id => (dispatch, getState, { api }) =>
  api.Operations.del(id)
    .then(p => {
      dispatch(notify('info', 'Operation removed'));
      return Promise.resolve(p);
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const groups = () => (dispatch, getState, { api }) =>
  api.Operations.groups().catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const pieces = () => (dispatch, getState, { api }) =>
  api.Operations.pieces().catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });
