import { baseUrl, createClient, postForm } from './util';

const partners = createClient('partners');

partners.photo = (id, file) => {
  if (!file) return;
  let fd = new FormData();
  fd.append('photo', file);
  return postForm(`${baseUrl}/partners/${id}/photo`, fd);
};

export default partners;
