import 'whatwg-fetch';
import lget from 'lodash/get';
import pluralizer from 'pluralize';
import { auth } from 'firebase';
import { capitalize } from '../formatters';
export const language =
  window.navigator.userLanguage || window.navigator.language;
export const baseUrl = `${process.env.REACT_APP_API_URL}`;
export const contentType = 'application/json';
export const headers = {
  'Content-Type': contentType,
  Accept: contentType,
  'x-api-version': 'v1'
};
export const mode = 'cors';

export const METHOD = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
  OPTIONS: 'OPTIONS'
};

export const tryConvertResponse = async res => {
  if (res.status >= 500) {
    let msg = '';
    await res.json().then(data => {
      msg = data;
    });
    let e = new Error(msg.message + ' - ' + res.status);
    throw e;
  }

  if (res.status === 401) {
    auth()
      .signOut()
      .then(function() {
        window.location.href = window.location.origin + '/login';
      })
      .catch(function(err) {
        let e = new Error(res.statusText + ' - ' + res.status);
        throw e;
      });
  }

  if (res.status >= 400 && res.status < 404) {
    let msg = '';
    await res.json().then(data => {
      msg = data;
    });
    let e = new Error(msg.message + ' - ' + res.status);
    throw e;
  }

  if (res.status === 404) {
    let msg = '';
    await res.json().then(data => {
      msg = data;
    });
    let e = new Error(msg.message + ' - ' + res.status);
    throw e;
  }

  if (res.status === 204 || res.status === 202) return Promise.resolve();

  return res.json();
};

/**
 * Add token to request
 *
 * @param token
 * @param url
 * @param options
 * @return {Promise<Response>}
 */
const fetchJson = (url, options = {}) =>
  getToken().then(token => {
    let finals = {
      mode,
      headers: new Headers({
        ...headers,
        'x-api-role': 'admin',
        Authorization: 'Bearer ' + token
      }),
      ...options
    };

    return fetch(url, finals).then(tryConvertResponse);
  });

function objectToParams(data) {
  return Object.keys(data)
    .map(key => {
      if (data[key]) return `${key}=${encodeURIComponent(data[key])}`;
      return '';
    })
    .join('&');
}

export const createQueryString = (params = {}) => {
  if (!params) return '';
  /*let urlParams = new URLSearchParams();
  Object.keys(params).forEach(te => {
    if (params[te] !== null) urlParams.append(te, params[te]);
  });
  */
  return '?' + objectToParams(params);
};

/**
 * Get data
 *
 * @param url
 * @return {*}
 */
export const get = url => fetchJson(url);

/**
 * Create
 *
 * @param url
 * @param data
 * @return {*}
 */
export const post = (url, data) =>
  fetchJson(url, { method: METHOD.POST, body: JSON.stringify(data) });

/**
 * Create
 *
 * @param url
 * @param formData
 * @return {*}
 */
export const postForm = (url, formData) =>
  getToken().then(token => {
    let finals = {
      mode,
      headers: new Headers({ Authorization: 'Bearer ' + token }),
      body: formData,
      contentType: true,
      method: METHOD.POST
    };

    return fetch(url, finals).then(tryConvertResponse);
  });

export const putForm = (url, formData) =>
  getToken().then(token => {
    let finals = {
      mode,
      headers: new Headers({ Authorization: 'Bearer ' + token }),
      body: formData,
      contentType: true,
      method: METHOD.PUT
    };

    return fetch(url, finals).then(tryConvertResponse);
  });

export const getToken = () =>
  new Promise(resolve => {
    resolve(localStorage.getItem('token'));
  });

/**
 * Update data
 *
 * @param url
 * @param data
 * @return {*}
 */
export const put = (url, data) =>
  fetchJson(url, { method: METHOD.PUT, body: JSON.stringify(data) });

/**
 * Delete
 *
 * @param url
 * @param data
 * @return {*}
 */
export const del = url => fetchJson(url, { method: METHOD.DELETE });

const plural = string => pluralizer.plural(string);
const singular = string => pluralizer.singular(string);

/**
 *
 * @param collection
 * @param otherEndpoints
 * @param baseUrl
 * @return {{add: (function(*=): *), update: (function(*, *=): *), all: (function(*=): *), one: (function(*): *), del: (function(*): *)}}
 */
export const createClient = (
  collection,
  otherEndpoints = {},
  baseUrl = process.env.REACT_APP_API_URL
) => {
  let reformedEndpoints = {};

  Object.keys(otherEndpoints).forEach(ep => {
    let methods =
      lget(otherEndpoints, ep) !== null
        ? lget(otherEndpoints, ep)
        : ['one', 'all', 'add', 'update', 'delete'];

    methods.forEach(method => {
      switch (method) {
        case 'all':
          reformedEndpoints['get' + plural(capitalize(ep))] = (
            id,
            params = {}
          ) =>
            get(
              `${baseUrl}/${plural(collection)}/${id}/${ep}${createQueryString(
                params
              )}`
            );
          break;
        case 'add':
          reformedEndpoints['add' + singular(capitalize(ep))] = (id, data) => {
            let func = data instanceof FormData ? postForm : post;

            return func(`${baseUrl}/${plural(collection)}/${id}/${ep}`, data);
          };
          break;

        case 'one':
          reformedEndpoints['get' + singular(capitalize(ep))] = (
            id,
            oId,
            params
          ) =>
            get(
              `${baseUrl}/${plural(collection)}/${id}/${plural(
                ep
              )}/${oId}${createQueryString(params)}`
            );
          break;

        case 'update':
          reformedEndpoints[method + singular(capitalize(ep))] = (
            id,
            oId,
            data
          ) =>
            put(
              `${baseUrl}/${plural(collection)}/${id}/${plural(ep)}/${oId}`,
              data
            );
          break;

        case 'delete':
          reformedEndpoints[method + singular(capitalize(ep))] = (id, oId) =>
            del(`${baseUrl}/${plural(collection)}/${id}/${plural(ep)}/${oId}`);
          break;

        default:
          break;
      }
    });

    reformedEndpoints['save' + singular(capitalize(ep))] = (id, data) =>
      data._id
        ? put(
            `${baseUrl}/${plural(collection)}/${id}/${plural(ep)}/${data._id}`,
            data
          )
        : post(`${baseUrl}/${plural(collection)}/${id}/${ep}`, data);
  });

  return {
    /**
     *
     * @param data
     * @return {*}
     */
    add: data => post(`${baseUrl}/${collection}`, data),

    /**
     *
     * @param id
     * @param data
     * @return {*}
     */
    update: (id, data) => put(`${baseUrl}/${collection}/${id}`, data),

    /**
     *
     * @param params
     * @return {*}
     */
    all: (
      params = {
        limit: 10,
        offset: 0
      }
    ) => get(`${baseUrl}/${collection}${createQueryString(params)}`),

    /**
     *
     * @param id
     * @return {*}
     */
    one: (id, params) =>
      get(`${baseUrl}/${collection}/${id}${createQueryString(params)}`),

    /**
     *
     * @param data
     * @return {*}
     */
    save: data =>
      data._id
        ? put(`${baseUrl}/${collection}/${data._id}`, data)
        : post(`${baseUrl}/${collection}`, data),

    /**
     *
     * @param id
     * @return {*}
     */
    del: id => del(`${baseUrl}/${collection}/${id}`),

    ...reformedEndpoints
  };
};
