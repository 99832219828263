import { baseUrl, createClient, createQueryString, get } from './util';

const client = createClient('leroymerlin');

client.byType = params =>
  get(`${baseUrl}/leroymerlin/type${createQueryString(params)}`);
client.byTypeAndId = params =>
  get(`${baseUrl}/leroymerlin/type/section${createQueryString(params)}`);
client.productsByTypeAndIdGet = params =>
  get(`${baseUrl}/leroymerlin/products${createQueryString(params)}`);
export default client;
