import {
  baseUrl,
  createClient,
  createQueryString,
  get,
  postForm,
  putForm
} from './util';

const client = createClient('user/documents');

client.all = params =>
  get(`${baseUrl}/user/documents/all${createQueryString(params)}`);

client.byUser = () => get(`${baseUrl}/user/documents`);

client.save = data => postForm(`${baseUrl}/user/documents`, data);

client.update = (id, data) => putForm(`${baseUrl}/user/documents/${id}`, data);

export default client;
