import moment from 'moment';

export const checkUpdates = (useLastCheck = true, from = null) => (
  dispatch,
  getState,
  { api }
) => {
  let lastCheck = localStorage.getItem('notifications.lastCheck');
  if (!lastCheck) {
    lastCheck = moment().unix();
  }

  let currents = getState().updates.data;

  api.Board.Notifications(
    useLastCheck || from ? { lastCheck: lastCheck || from } : null
  )
    .then(({ data, meta }) => {
      localStorage.setItem('notifications.lastCheck', moment().unix());

      Object.keys(data).forEach(entity => {
        data[entity].forEach(notification => {
          // check if the notification already exist in localStorage
          let index = currents.findIndex(
            x =>
              x._id &&
              x._id === notification._id &&
              x.createdAt === notification.createdAt &&
              x.updatedAt === notification.updatedAt
          );
          // check if exist another notification for the same element
          let same = currents.findIndex(
            x => x.item && x.item._id && x.item._id === notification.item._id
          );

          // if notification not exist create a new
          if (index === -1 && same === -1) {
            notification.amount = 1;
            notification.list = [notification];
            dispatch({
              type: 'RT_NOTIFICATION_ADD',
              payload: {
                ...notification
              },
              lastCheck: lastCheck || from
            });
          }
          // if notification exist for the same element update amount and list
          if (same !== -1) {
            currents[same].amount++;
            currents[same].createAt = notification.createAt;
            currents[same].list.push(notification);
            dispatch({
              type: 'RT_NOTIFICATION_UPDATE',
              payload: currents,
              lastCheck: lastCheck || from
            });
          }
        });
      });
    })
    .catch(() => {});
};
